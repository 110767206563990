<template>
  <div v-if="!!programId">
    <a-spin :spinning="loading">
      <SpComment 
        v-for="comment in comments" 
        :key="comment.id" 
        :comment="comment"
      />
    </a-spin>

    <a-alert 
      v-if="alreadyPosted"
      message="Ваше предложение отправлено!" 
      type="success" 
    />
    <a-button 
      v-else
      @click="createDialogVisible = true"
    >
      Оставить предложение или замечание
    </a-button>

    <SpCommentCreateDlg
      v-if="!loading"
      :programId="programId"
      :programTitle="programTitle"
      :visible="createDialogVisible"
      @save="onCommentSave"
      @close="createDialogVisible = false"
    />
  </div>
  <div v-else>
    Доступно после сохранения
  </div>
</template>

<script>
import SpComment from "./sp-program-comment.vue";
import SpCommentCreateDlg from "./sp-program-comment-create-dialog.vue";
import api from "@/api";

export default {
  name: "sp-program-comment-list",
  components: {
    SpComment,
    SpCommentCreateDlg,
  },
  props: {
    programId: { type: Number },
    titleId: { type: Number },
  },
  data() {
    return {
      comments: [],
      createDialogVisible: false,
      loading: false,
      alreadyPosted: false,
      programTitle: null
    }
  },
  watch: {
    programId: {
      immediate: true,
      async handler() {
        await this.refresh();
      }
    }
  },
  methods: {
    async refresh() {
      this.loading = true;
      await this.$nextTick();
      this.comments = await api.comment.getPublishedComments(this.programId);
      if (this.titleId)
        this.programTitle = (await api.programTitle.getAll()).find(x => x.id == this.titleId)?.title || "";
      else this.programTitle = "";
      this.loading = false;
    },
    async onCommentSave() {
      this.alreadyPosted = true;
    }
  }
}
</script>

<style>

</style>