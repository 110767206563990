<template>
  <grid
    v-if="hasId"
    :patchProps="patchProps"
    :filters="filters"
    :representService="gridRepresentService"
    :readonly="disabled"
    :hiddenFields="hiddenPropNames"
  />
  <span v-else>Доступно после сохранения</span>
</template>
<script>
import api from "@/api";
import RepresentService from "@/services/represent-service";
import grid from "@/components/grid.vue"

export default {
  name: "sp-generic-subtable",
  components:{
    grid
  },
  props: {
    value: { type: Number },
    innerType: { type: String, required: true },
    keyPropName: { type: String, required: true },
    hiddenPropNames: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    formState: {
      type: Object,
      default: () => {},
    },
  },
  computed:{
    hasId(){
      return !!this.formState.id
    }
  },
  data() {
    const vm = this;
    
    let patch = {}
    patch[vm.keyPropName] = vm.formState.id
    
    return {
      patchProps: patch,
      gridRepresentService: new RepresentService(vm.innerType),
      filters: [{field: vm.keyPropName, equal: vm.formState.id}],
    };
  },
  async created() {
  },
  methods: {
    
  }
};
</script>