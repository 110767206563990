<template>
  <a-radio-group
    v-if="visible"
    v-model:value="valueComputed"
  >
    <a-radio :value="1">
      Да
    </a-radio>
    <a-radio :value="2">
      Нет
    </a-radio>
  </a-radio-group>
</template>

<script>
import api from "@/api";

export default {
  name: "grid-filer-item-equal",
  components: {
  },
  props: {
    value: { type: String },
    formState: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    valueComputed: {
      get() {
        if(this.value === true || this.value === "true" || this.value === "True") return 1
        else return 2
      },
      set(value) {
        this.$emit("update:value", value === 1)
        this.$emit("change", {name:"equal"})
      }
    },
  },
  data() {
    return {
      visible: false,
      selectOptions: []
    }
  },
  methods:{
    async handle(field){
      if (!field || field !== "GIAExam") {
        this.$emit("update:value", {});
        this.visible = false;
        return;
      }

      this.visible = true;
    }
  },
  async created() {
    await this.handle(this.formState.field)    
    
    this.$watch(
      () => this.formState.field,
      async (field, prevField) => {
        await this.handle(field)
      },
      { deep: true })
    
    this.selectOptions = await api.entityConfig.filteredFields()
  }
}
</script>