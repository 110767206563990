<template>
  <a-select
    class="w-100"
    :options="selectOptions"
    v-model:value="valueComputed"
  />
</template>

<script>
import api from "@/api";

export default {
  name: "grid-filer-item-field",
  components: {
  },
  computed:{
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
  },
  props: {
    value: { type: String },
    formState: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
  },
  data() {
    return {
      val: this.value,
      selectOptions: []
    }
  },
  async created() {    
    this.selectOptions = await api.entityConfig.filteredFields()
  }
}
</script>