<template>
  <a-select 
    v-bind="attrs"
    v-model:value="valueComputed"
    optionFilterProp="label"
    :options="options"
    mode="multiple"
  />
</template>

<script>
import api from "@/api";
import { omit } from "lodash";
export default {
  name: "sp-user-role-type",
  props: {
    value: { type: Array },
  },
  data() {
    return {
      options: []
    };
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
    attrs() { 
      return omit(this.$attrs, "onUpdate:value"); 
    },
  },
  async created() {
    let options = await api.entityConfig.userRoleTypes();
    this.options = options.map(x => ({ label: x.title, value: x.value }));
  },
}
</script>

<style>

</style>