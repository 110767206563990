import api from "@/api";
import CrudService from "./crud-service";

export default class DependentTableCrudService extends CrudService {
  constructor(type, sourceType, targetType, parentId, programId) {
    super(type);
    this.sourceType = sourceType;
    this.targetType = targetType;
    this.parentId = parentId;
    this.programId = programId;
  }

  async gridData(filters = {}) {
    return await api.dependent.gridData(this.targetType, this.sourceType, this.parentId, this.programId, filters);
  }

  async create() {
    throw new Error('Not allowed');
  }

  async update() {
    throw new Error('Not allowed');
  }

  async delete() {
    throw new Error('Not allowed');
  }
}
