<template>
  <div @click="(e) => $emit('click', e)">
    <a-row
      v-if="element.type === itemTypes.grid"
      :gutter="element.options.gutter ? +element.options.gutter : 0"
      :justify="element.options.justify"
      :align="element.options.align"
    >
      <a-col
        v-for="(col, colIndex) in element.columns"
        :key="colIndex"
        :span="col.span ? col.span : 0"
      >
        <slot name="item" :item="col" :index="colIndex" />
      </a-col>
    </a-row>

    <a-tabs 
      v-else-if="element.type == itemTypes.tabs"
      v-model:activeKey="currentTab"
      :animated="false"
    >
      <a-tab-pane 
        v-for="(tab, tabIndex) in element.tabs"
        :key="tabIndex"
        :tab="tab.title"
      >
        <slot name="item" :item="tab" :index="tabIndex" />
      </a-tab-pane>
    </a-tabs>
    
    <template v-else>
      <span>Неизвестный компонент с типом {{element.type}}</span>
    </template>

    <slot name="after" />
  </div>
</template>

<script>
import { itemTypes } from "@/helpers/form-generator";
export default {
  name: "sp-form-generator-layout-item",
  props: {
    element: { type: Object, required: true }
  },
  emits: ["click"],
  data() {
    return {
      itemTypes: itemTypes,
      currentTab: 0
    }
  }
}
</script>

<style lang="less" scoped>
  .component_label {
    white-space: pre;
    min-height: 20px;
  }
</style>