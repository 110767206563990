<template>
  <a-select 
    v-bind="attrs"
    v-model:value="valueComputed"
    showSearch
    optionFilterProp="label"
    :options="options"
  />
</template>

<script>
import api from "@/api";
import { error } from "@/helpers/notifications";
import { omit } from "lodash";
export default {
  name: "sp-reference",
  props: {
    value: { type: Object, default: () => ({}) },
    allowedSelectOptions: { type: Array, default: () => ([]) },
    type: { type: String },
    field: { type: String },
    optionsGetter: { type: Function },
    representSystemName: { type: String },
    
    formState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: []
    };
  },
  computed: {
    valueComputed: {
      get() {
        return this.value?.id;
      },
      set(value) {
        this.$emit("update:value", { id: value });
      }
    },
    attrs() { 
      return omit(this.$attrs, "onUpdate:value"); 
    },
    ugpsId(){
      return this.formState?.ugps?.id;
    }
  },
  created() {

    this.$watch(
      () => this.allowedSelectOptions,
      async watchedValue => {
        await this.loadOptions()
      },
      { deep: true, immediate: true })

    this.$watch(
      () => this.type,
      async watchedValue => {
        await this.loadOptions()
      },
      { deep: true, immediate: true })
    
    if(this.field === "fgos" && this.representSystemName === "SampleProgram"){
      this.$watch(
        () => this.ugpsId,
        async watchedValue => {
          await this.loadOptions()
        },
        { deep: true, immediate: true })
    }
  },
  methods:{
    async loadOptions(){
      const vm = this;

      if (!vm.type) {
        vm.options = [];
        return;
      }

      try {
        let options = vm.optionsGetter
          ? await vm.optionsGetter(vm.type)
          : await api.reference.getOptions(vm.type, vm.formState);
        vm.options = options.map(x => ({ label: x.title, value: x.id, disabled: vm.isOptionDisabled(x.id) }));
      }
      catch(e) {
        error(`Не удалось получить данные для выпадающего списка (тип '${vm.type}')`);
        console.error(e);
        vm.options = [];
      }
    },
    isOptionDisabled(id){
      if(this.allowedSelectOptions.length){
        return !this.allowedSelectOptions.includes(id)
      }
      return false
    }
  },
}
</script>

<style>

</style>