<template>
  <a-modal 
    v-model:visible="innerVisible"
    title="Добавить комментарий"
    :confirm-loading="loading"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-form
      :model="form"
      :rules="rules"
      ref="form"
      layout="vertical"
      class="mb-4"
      @finish="sendData"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item name="surname" label="Фамилия">
        <a-input v-model:value="form.surname" />
      </a-form-item>

      <a-form-item name="name" label="Имя">
        <a-input v-model:value="form.name" />
      </a-form-item>

      <a-form-item name="patronymic" label="Отчество">
        <a-input v-model:value="form.patronymic" />
      </a-form-item>

      <a-form-item name="job" label="Место работы">
        <a-input v-model:value="form.job" />
      </a-form-item>

      <a-form-item name="position" label="Должность">
        <a-input v-model:value="form.position" />
      </a-form-item>

      <a-form-item name="phone" label="Телефон">
        <a-input v-model:value="form.phone" />
      </a-form-item>

      <a-form-item name="email" label="E-mail">
        <a-input v-model:value="form.email" />
      </a-form-item>

      <a-form-item name="comments" :label="commentsLabel">
        <a-textarea v-model:value="form.comments" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { required, email } from "@/helpers/validation.js";
import api from "@/api";

export default {
  name: "sp-program-comment-create-dialog",
  props: {
    visible: { type: Boolean, required: true },
    programId: { type: Number, required: true },
    programTitle: { type: String, required: true },
  },
  emits: ["close", "save"],
  data() {
    return {
      innerVisible: false,
      loading: false,
      form: {
        surname: null,
        name: null,
        patronimyc: null,
        job: null,
        position: null,
        phone: null,
        email: null,
        comments: null,
      },
      rules: {
        surname: [required("Фамилия")],
        name: [required("Имя")],
        patronymic: [required("Отчество")],
        email: [required("E-mail"), email()],
        comments: [required("Предложение или замечание")],
      },
    }
  },
  computed: {
    commentsLabel() {
      return "Предложение или замечание к ПООП СПО \"" + this.programTitle + "\"";
    }
  },
  watch: {
    visible: {
      handler(value) {
        if (value != this.innerVisible)
          this.innerVisible = value;
        if (value)
          this.resetItemData();
      },
      immediate: true
    }
  },
  methods: {
    resetItemData() {
      this.form = {
        surname: null,
        name: null,
        patronymic: null,
        job: null,
        position: null,
        phone: null,
        email: null,
        comments: null,
      };
    },
    async onOk() {
      await this.$refs.form.validate();
      await this.sendData();
    },
    async sendData() {
      this.loading = true;
      try {
        await api.comment.createComment(this.programId, this.form);
      }
      catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$emit("close");
      this.$emit("save");
    },
    onCancel() {
      this.$emit("close");
    },
    handleFinishFailed(e) {
      console.log(e);
    }
  }
}
</script>

<style>

</style>