<template>
  <div v-if="value > 0 && !!crudService"
    class="card" style="margin-bottom: 0"
  >
    <div class="card-header" v-if="!disabled">
      <a-space>
        <a-button
          :disabled="!isLoggedIn || !selectedKeys?.length || programId === 0"
          @click="hideSelected"
        >
          <template #icon><i class="fa fa-trash" /></template>
        </a-button>
        <a-button
          :disabled="!isLoggedIn || programId === 0"
          @click="resetHidden"
        >
          <template #icon><i class="fa fa-undo" /></template>
        </a-button>
      </a-space>
    </div>
    <grid 
      ref="grid"
      :representService="representService"
      :crudService="crudService"
      :key="crudService.parentId"
      :toolbarVisible="false"
      :readonly="true"
      @selectionChanged="onSelectionChanged"
    />
  </div>
  <span v-else>Доступно после сохранения</span>
</template>

<script>
import RepresentService from "@/services/represent-service";
import DependentTableCrudService from "@/services/dependent-table-crud-service";
import CrudService from "@/services/crud-service";
import grid from "@/components/grid.vue";
import { showConfirm } from "@/helpers/modals";
import { success, error } from "@/helpers/notifications";

export default {
  name: "sp-dependent-subtable",
  components: {
    grid
  },
  props: {
    value: { type: Number, default: 0 },
    formState: { type: Object, default: () => ({}) },
    innerType: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      representService: new RepresentService(this.innerType),
      crudService: null,
      spCrudService: new CrudService("SampleProgram"),
      selectedKeys: []
    };
  },
  methods: {
    onSelectionChanged(keys) {
      this.selectedKeys = keys;
    },
    async hideSelected() {
      if (!this.selectedKeys.length)
        return;

      showConfirm({ 
        text: "Вы действительно хотите удалить выбранные записи?",
        ok: async () => {
          await this.saveHiddenIds(this.selectedKeys);
          this.selectedKeys = [];
        },
      });
    },
    async resetHidden() {
      showConfirm({ 
        text: "Вы действительно хотите вернуть все удаленные записи?",
        ok: async () => await this.saveHiddenIds([]),
      });
    },
    async saveHiddenIds(hiddenIds) { 
      try {
        await this.spCrudService.update({ 
          id: this.programId,
          hiddenQualificationIds: hiddenIds
        });
        await this.$refs.grid.loadGridData();
        success("Сохранено");
      }
      catch(e) {
        console.error(e);
        error("Ошибка при сохранении");
      }
    }
  },
  computed: {
    programId() {
      return this.formState.id;
    },
    isLoggedIn() {
      return this.$auth.isLoggedIn();
    },
  },
  created() {
    if (!this.value || !this.innerType)
      return;

    let watchedEntity = getWatchedEntity(this.innerType);
    if (!watchedEntity) 
      throw Error("Cannot find watchable field for type " + this.innerType);
    this.$watch(
      () => this.formState[watchedEntity], 
      async watchedValue => {
        this.crudService = new DependentTableCrudService(this.innerType, watchedEntity, getTypeName(this.innerType), watchedValue.id, this.programId);
      }, 
      { deep: true, immediate: true })
  }
}

function getWatchedEntity(type) {
  switch(type.toLowerCase()) {
    case "graduatequalification":
      return "fgos";
  }
}

function getTypeName(type) {
  switch(type.toLowerCase()) {
    case "graduatequalification":
      return "graduate_qualifications";
  }
}
</script>