<template>
  <a-input
    v-if="visible"
    placeholder="Искать в наименовании"
    v-model:value="valueComputed"
  />
</template>

<script>
import api from "@/api";

export default {
  name: "grid-filer-item-contains",
  components: {
  },
  props: {
    value: { type: String },
    formState: {
      type: Object,
      default: () => {},
    },
    propName:{ type: String}
  },
  computed: {
    valueComputed: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("update:value", value)
        this.$emit("change", {name:this.propName})
      }
    },
  },
  data() {
    return {
      visible: false,
      selectOptions: []
    }
  },
  methods:{
    async handle(field){
      if(field && (field.toLowerCase() === "regnumber" || (field.includes(".") && field.split(".")[1].toLowerCase() === "title"))){
        this.visible = true        
      } else{
        this.$emit("update:value", "");
        this.visible = false;
      }
    }
  },
  async created() {
    await this.handle(this.formState.field)    
    
    this.$watch(
      () => this.formState.field,
      async (field, prevField) => {
        await this.handle(field)
      },
      { deep: true })
    
    this.selectOptions = await api.entityConfig.filteredFields()
  }
}
</script>