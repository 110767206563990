import api from "@/api";
import CrudService from "./crud-service";

export default class SubtableExCrudService extends CrudService {
  constructor(type, ownerId) {
    super(type);
    this.ownerId = ownerId;
  }

  async gridData(filters = {}) {
    return await api.subtableEx.gridData(this.type, this.ownerId, filters);
  }

  async create(obj) {
    return await api.subtableEx.create(this.type, this.ownerId, obj);
  }
}
