<template>
  <Reference
    v-bind="$attrs"
    :type="type"
    :optionsGetter="optionsGetter"
    :key="watchedValue"
  />
</template>

<script>
import api from "@/api";
import Reference from "@/components/editors/sp-reference.vue";
export default {
  name: "sp-dependent-reference",
  components: {
    Reference
  },
  props: {
    type: { type: String },
    value: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
    formState: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      optionsGetter: null,
      watchedValue: 0
    };
  },
  computed: {
  },
  created() {
    if (!this.type)
      return;
    let watchedEntity = getWatchedEntity(this.type);
    if (!watchedEntity) 
      throw Error("Cannot find watchable field for type " + this.type);
    this.$watch(
      () => this.formState[watchedEntity], 
      async watchedValue => {
        this.watchedValue = watchedValue?.id ?? 0;
        this.optionsGetter = async () => await api.dependent.getReferenceOptions(this.type, watchedEntity, this.watchedValue);
      }, 
      { 
        deep: true, 
        immediate: true 
      }
    );
  }
}

function getWatchedEntity(type) {
  switch(type.toLowerCase()) {
    case "fgos":
      return "ugps";
  }
}
</script>