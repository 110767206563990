<template>
  <a-form-item
    :key="element.key"
    :label="!options.hideLabel ? element.label || type : null"
    :labelCol="labelCol"
    :colon="showColon"
    :wrapperCol="wrapperCol"
    :class="style.formItem"
  >
    <a-input
      v-if="type === itemTypes.string"
      v-model:value="valueComputed"
      :style="{ width: options.width }"
      :placeholder="options.placeholder"
      :disabled="options.disabled"
      :allow-clear="options.clearable"
      v-bind="options"
      @change="onChange"
    />
    <a-textarea
      v-else-if="type === itemTypes.text"
      type="textarea"
      :rows="5"
      v-model:value="valueComputed"
      :style="{ width: options.width }"
      :disabled="options.disabled"
      :placeholder="options.placeholder"
      v-bind="options"
      @change="onChange"
    />
    <a-input-number
      v-else-if="type === itemTypes.number"
      v-model:value="valueComputed"
      :disabled="options.disabled"
      :style="{ width: options.width }"
      :precision="options.precision"
      v-bind="options"
      @change="onChange"
    />
    <a-switch
      v-else-if="type === itemTypes.boolean"
      v-model:checked="valueComputed"
      :disabled="options.disabled"
      v-bind="options"
      @change="onChange"
    />
    <a-date-picker
      v-else-if="type === itemTypes.date"
      v-model:value="valueComputed"
      :type="options.type"
      :placeholder="options.placeholder"
      :readonly="options.readonly"
      :disabled="options.disabled"
      :allow-clear="options.clearable"
      format="L"
      :style="{ width: options.width }"
      v-bind="options"
      @change="onChange"
    />
    <a-range-picker
      v-else-if="type === itemTypes.datePeriod"
      v-model:value="valueComputed"
      :type="options.type"
      :placeholder="options.startPlaceholder || options.endPlaceholder ? [options.startPlaceholder, options.endPlaceholder] : null"
      :readonly="options.readonly"
      :disabled="options.disabled"
      :allow-clear="options.clearable"
      format="L"
      :style="{ width: options.width }"
      v-bind="options"
      @change="onChange"
    />
    <Reference
      v-else-if="type === itemTypes.reference"
      v-model:value="valueComputed"
      :type="referenceType"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :disabled="options.disabled"
      :allowedSelectOptions="options.allowedSelectOptions"
      @change="onChange"
      v-bind="options"
      :formState="formState"
      :representSystemName="represent.systemName"
      :field="element.name"
    />
    <CommentList
      v-else-if="type === itemTypes.commentList"
      :programId="formState.id"
      :titleId="formState.programTitle ? formState.programTitle.id : null"
      v-bind="options"
      @change="onChange"
    />
    <FileInput
      v-else-if="type === itemTypes.files"
      v-model:value="valueComputed"
      v-bind="options"
      :disabled="options.disabled"
      :bucketName="represent.systemName"
      @update:value="onChange"
    />
    <GenericSubtable
      v-else-if="type === itemTypes.genericSubtable"
      v-model:value="valueComputed"
      :formState="formState"
      v-bind="options"
      :disabled="options.disabled"
    />
    <Subtable
      v-else-if="type === itemTypes.subtable"
      v-model:value="valueComputed"
      v-bind="options"
      :disabled="options.disabled"
    />
    <SubtableEx
      v-else-if="type === itemTypes.subtableEx"
      v-model:value="valueComputed"
      v-bind="options"
      :disabled="options.disabled"
    />
    <MultipleReference
      v-else-if="type === itemTypes.multipleReference"
      v-model:value="valueComputed"
      :formState="formState"
      v-bind="options"
      :disabled="options.disabled"
    />
    <Dependent
      v-else-if="type === itemTypes.dependent"
      v-model:value="valueComputed"
      :formState="formState"
      :field="element.name"
      :options="options"
      @update:value="onChange"
    />
    <DependentTable
      v-else-if="type === itemTypes.dependentSubtable"
      v-model:value="valueComputed"
      :formState="formState"
      v-bind="options"
      :disabled="options.disabled"
    />
    <DependentReference
      v-else-if="type === itemTypes.dependentReference"
      v-model:value="valueComputed"
      :type="referenceType"
      :formState="formState"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :disabled="options.disabled"
      @change="onChange"
      v-bind="options"
    />

    <SpEditRequests
      v-else-if="type === itemTypes.spEditRequests"
      :formState="formState"
    />

    <DynamicFieldType
      v-else-if="type === itemTypes.dynamicFieldType"
      v-model:value="valueComputed"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :disabled="options.disabled"
      @change="onChange"
      v-bind="options"
    />

    <UserRoleType
      v-else-if="type === itemTypes.userRoleType"
      v-model:value="valueComputed"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :disabled="options.disabled"
      @change="onChange"
      v-bind="options"
    />

    <QuillEditor 
      v-else-if="type === itemTypes.html"
      theme="snow"
      toolbar="full"
      contentType="html"
      :modules="quillModules"
      v-model:content="htmlValueComputed"
      @update:content="onChange"
    />

    <GridFilerItemField
      v-else-if="type === itemTypes.gridFilerItemField"
      v-model:value="valueComputed"
      :formState="formState"
      @change="onChange"
    />

    <GridFilerItemEqual
      v-else-if="type === itemTypes.gridFilerItemEqual"
      v-model:value="valueComputed"
      :formState="formState"
      @change="onChange"
    />

    <GridFilerItemContains
      v-else-if="type === itemTypes.gridFilerItemContains"
      v-model:value="valueComputed"
      :formState="formState"
      propName="contains"
      @change="onChange"
    />

    <GridFilerItemContains
      v-else-if="type === itemTypes.gridFilerItemNotContains"
      v-model:value="valueComputed"
      :formState="formState"
      propName="notContains"
      @change="onChange"
    />

    <span v-else-if="type === itemTypes.label"></span>

    <!-- <el-cascader
      v-else-if="type === 'cascader'"
      v-model="valueComputed"
      :disabled="options.disabled"
      :allow-clear="options.clearable"
      :placeholder="options.placeholder"
      :style="{ width: options.width }"
      :options="options.remoteOptions"
      @change="onChange"
    /> -->

    <template v-else>
      <span>Неизвестный компонент с типом {{ type }}</span>
    </template>
  </a-form-item>
</template>

<script>
import style from '@/css/components/grid.module.scss'

import FileInput from "@/components/editors/sp-file-input.vue";
import Reference from "@/components/editors/sp-reference.vue";
import Subtable from "@/components/editors/sp-subtable.vue";
import SubtableEx from "@/components/editors/sp-subtable-ex.vue";
import GenericSubtable from "@/components/editors/sp-generic-subtable.vue";
import Dependent from "@/components/editors/sp-dependent.vue";
import DependentTable from "@/components/editors/sp-dependent-subtable.vue";
import DependentReference from "@/components/editors/sp-dependent-reference.vue";
import SpEditRequests from "@/components/editors/sp-edit-requests.vue";
import CommentList from "@/components/program-comments/sp-program-comment-list.vue";
import DynamicFieldType from "@/components/editors/sp-dynamic-field-type.vue";
import MultipleReference from "@/components/editors/sp-multiple-reference.vue";
import UserRoleType from "@/components/editors/sp-user-role-type.vue";

import GridFilerItemField from "@/components/editors/grid-filer-item-field.vue";
import GridFilerItemEqual from "@/components/editors/grid-filer-item-equal.vue";
import GridFilerItemContains from "@/components/editors/grid-filer-item-contains.vue";

import { QuillEditor } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter';

import {itemTypes, getReferenceType, getTypeName} from "@/helpers/form-generator";

export default {
  name: "sp-form-generator-item",
  components: {
    FileInput,
    Reference,
    CommentList,
    Subtable,
    SubtableEx,
    GenericSubtable,
    Dependent,
    DependentTable,
    DependentReference,
    SpEditRequests,
    DynamicFieldType,
    MultipleReference,
    UserRoleType,
    QuillEditor,

    GridFilerItemField,
    GridFilerItemEqual,
    GridFilerItemContains,
  },
  props: {
    value: {},
    element: {type: Object, required: true},
    formState: {type: Object, default: () => ({})},
    represent: {type: Object, required: false, default: () => ({systemName: "Shared"})},
  },
  data() {
    return {
      style,
      itemTypes: itemTypes,
    };
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
    htmlValueComputed: {
      get() {
        return this.value || "";
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
    options() {
      return this.element.options || {};
    },
    type() {
      return getTypeName(this.element.type)
    },
    referenceType() {
      return getReferenceType(this.element.type);
    },
    labelCol() {
      let options = this.element.options;
      if (this.element.type === "label") {
        return {
          style: {
            "white-space": "pre",
            "margin-top": "44px",
            "margin-bottom": "22px",
            "font-weight": options?.bold ? "bold" : null,
            "font-style": options?.italic ? "italic" : null,
          },
          //"class": "multiline",
        };
      }
      if (options?.labelVertical)
        return {style: {width: "100%", textAlign: "left"}};
      if (options?.labelWidth)
        return {style: {width: options.labelWidth}};
      return {span: 6};
    },
    wrapperCol() {
      let options = this.element.options;
      if (options?.labelWidth)
        return {style: {width: `'calc(100% - ${options.labelWidth})'`}};
      return {span: 18};
    },
    showColon() {
      return this.element.type !== "label";
    },
    quillModules() { 
      return {
        name: 'blotFormatter',  
        module: BlotFormatter, 
        options: {}
      }
    }
  },
  methods: {
    onChange(e) {
      this.$emit("addChange", e);
    }
  }
};
</script>