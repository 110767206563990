<template>
  <a-input
    disabled
    :value="valueComputed"
    :style="{ width: options.width }"
  />
</template>

<script>
import api from "@/api";
export default {
  name: "sp-dependent",
  props: {
    value: { type: Object, default: () => ({}) },
    field: { type: String },
    options: { type: Object, default: () => ({}) },
    formState: { type: Object, default: () => ({}) },
  },
  computed: {
    valueComputed() {
      return this.value?.title;
    },
  },
  created() {
    let watchedEntity = getWatchedEntity(this.field);
    if (!watchedEntity) 
      throw Error("Cannot find watchable field for field " + this.field);
    this.$watch(
      () => this.formState[watchedEntity], 
      async watchedValue => {
        if (!watchedValue) {
          this.$emit("update:value", {});
          return;
        }

        let value = await api.dependent.getEntityField(watchedEntity, watchedValue.id, this.field);
        this.$emit("update:value", value || null);
      }, 
      { deep: true })
  }
}

function getWatchedEntity(field) {
  switch(field.toLowerCase()) {
    case "laboratory":
    case "umo":
      return "ugps";

    case "profspecialization":
    case "programtitle":
    case "programlevel":
      return "fgos";
  }
}
</script>