<template>
  <div class="mb-5">
    <div class="mb-2">
      <div className="font-weight-bold" :data-created="comment.created"> 
        {{comment.surname}} {{comment.name}} {{comment.patronimyc}}
      </div>
      <div>{{comment.job}}, {{comment.position}}</div>
      <div v-if="comment.phone || comment.email">
        тел.: {{comment.phone }} e-mail: {{comment.email}}
      </div>
    </div>
    <div>
      <p>
        {{comment.comments}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sp-program-comment',
  props: {
    comment: { type: Object, required: true }
  }
}
</script>