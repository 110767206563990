<template>
  <a-upload
    :multiple="true"
    :file-list="fileList"
    :remove="handleRemove"
    :before-upload="beforeUpload"
    @change="onChange"
  >
    <a-button :disabled="disabled" type="primary"> Загрузить </a-button>
  </a-upload>
</template>
<script>
import api from "@/api";

export default {
  name: "sp-file-input",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    bucketName: {
      type: String,
      default: "Shared",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
      user: {}
    };
  },
  methods: {
    onChange(){
      
    },
    handleRemove(file) {
      const vm = this;
      if(!vm.user)
        return false;
      
      const index = vm.fileList.indexOf(file);
      const newFileList = vm.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      
      this.emitChangeValue();
    },
    emitChangeValue(){
      const vm = this;

      let fileIds = JSON.stringify(
        vm.fileList.map((i) => {
          return i.uid;
        })
      );

      let newValue = vm.value ?? {};
      newValue.fileIds = fileIds;
      
      vm.$emit('update:value', newValue);
    },
    async beforeUpload(file) {
      const vm = this;

      file.status = "uploading";
      let tempFileList = vm.fileList;
      vm.fileList = [...tempFileList, file];

      let uniqBucketName = vm.bucketName + '-' + self.crypto.randomUUID();

      let uploadUrl = await api.filestorage.upload(uniqBucketName, file.name);

      fetch(uploadUrl, {
        method: "PUT",
        body: file,
      })
        .then(async () => {
          file.uid = await api.crud.create("file", {
            BucketName: uniqBucketName,
            ObjectName: file.name,
          });
          file.status = "done";
  
          vm.fileList = [...tempFileList, file];
          vm.emitChangeValue();
        })
        .catch((e) => {
          file.status = "error";
        });

      return false;
    },
  },
  async created() {
    const vm = this;

    vm.user = await api.userManager.myUser()

    if(vm.value){
      for (const item of vm.value.files) {
        let fileInfo = await api.crud.get("file", item);
        let url = await api.filestorage.download(fileInfo.id);
        vm.fileList.push({
          uid: fileInfo.id,
          name: fileInfo.objectName,
          status: "done",
          url: url,
        });
      }
    }
  },
};
</script>