<template>
  <a-select 
    v-if="hasId"
    showSearch
    mode="multiple"
    optionFilterProp="label"
    :options="options"
    @change="handleChange"
    :value="selectedItems"
    :disabled="disabled"
  />
  <span v-else>Доступно после сохранения</span>
</template>

<script>
import api from "@/api";
import { error } from "@/helpers/notifications";

export default {
  name: "sp-multiple-reference",
  props: {
    value: { type: Object, default: () => ({}) },
    type: { type: String, required: true },
    innerType: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    
    formState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: [],
      selectedItems: [],
    };
  },
  computed: {
    hasId(){
      return !!this.formState.id
    },
    keyPropName(){
      let key = this.innerType + 'Id'
      return key.charAt(0).toLowerCase() + key.slice(1)
    }
  },
  created() {

    this.$watch(
      () => this.innerType,
      async watchedValue => {
        await this.loadOptions()
      },
      { deep: true, immediate: true })
  },
  methods:{
    async handleChange(val){
      const vm = this
      
      let exist = (await api.crud.gridData(vm.type, { Items:[{field: "SampleProgramId", equal: '' + vm.formState.id}]})).data
      
      val.filter(function(id){
        return !exist.some(function(item){
          return id === item[vm.keyPropName]
        })
      }).forEach(function(item, i, arr) {
        let entity = {SampleProgramId: vm.formState.id}
        entity[vm.keyPropName] = item
        api.crud.create(vm.type, entity)
      })

      exist.filter(function(item){
        return !val.some(function(id){
          return id === item[vm.keyPropName]
        })
      }).forEach(function(item, i, arr) {
        api.crud.remove(vm.type, item.id)
      })
    },
    async loadOptions(){
      const vm = this;

      if (!vm.innerType) {
        vm.options = [];
        return;
      }

      try {
        let options = (await api.crud.gridData(vm.innerType)).data
        vm.options = options.map(x => ({ label: x.title, value: x.id }));
      }
      catch(e) {
        error(`Не удалось получить данные для выпадающего списка (тип '${vm.innerType}')`);
        console.error(e);
        vm.options = [];
      }

      let exist = (await api.crud.gridData(vm.type, { Items:[{field: "SampleProgramId", equal: '' + vm.formState.id}]})).data
      vm.selectedItems = exist.map(function(item){
        return item[vm.keyPropName]
      })
    },
  },
}
</script>

<style>

</style>