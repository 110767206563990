import api from "@/api";
import CrudService from "./crud-service";

export default class SubtableCrudService extends CrudService {
  constructor(type, parentId) {
    super(type);
    this.parentId = parentId;
  }

  async gridData(filters = {}) {
    return await api.subtable.gridData(this.type, this.parentId, filters);
  }

  async create(obj) {
    return await api.subtable.create(this.type, this.parentId, obj);
  }
}
