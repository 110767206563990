<template>
  <grid 
    v-if="value > 0"
    :representService="representService"
    :crudService="crudService"
    :readonly="disabled"
  />
  <span v-else>Доступно после сохранения</span>
</template>

<script>
import RepresentService from "@/services/represent-service";
import SubtableCrudService from "@/services/subtable-crud-service";
import grid from "@/components/grid.vue";

export default {
  name: "sp-subtable",
  components: {
    grid
  },
  props: {
    value: { type: Number },
    innerType: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      representService: new RepresentService(this.innerType),
      crudService: new SubtableCrudService(this.innerType, this.value),
    }
  },
}
</script>