<template>
  <div v-if="hasId">
    <div v-if="roleType === 1">
      <grid
        :patchProps="patchProps"
        :filters="filters"
        :representService="gridRepresentService"
        :hiddenFields="['sampleProgram']"
      />
    </div>
    <div v-else>
      <a-button v-if="!isRequestExist" type="primary" @click="sendRequest"> Отправить запрос </a-button>
      <span v-else >{{message}}</span>
    </div>
  </div>
  <span v-else>Доступно после сохранения</span>
</template>
<script>
import api from "@/api";
import RepresentService from "@/services/represent-service";
import grid from "@/components/grid.vue"

export default {
  name: "sp-edit-requests",
  components:{
    grid
  },
  props: {
    formState: {
      type: Object,
      default: () => {},
    },
  },
  computed:{
    hasId(){
      return !!this.formState.id
    }
  },
  data() {
    const vm = this;
    return {
      message: "Запрос отправлен",
      userId: 0,
      isRequestExist: true,
      roleType: 0,
      patchProps: {SampleProgramId: vm.formState.id},
      gridRepresentService: new RepresentService("SpEditRequest"),
      requests: [],
      filters: [],
    };
  },
  methods: {
    async sendRequest() {
      const vm = this;
      if(vm.userId){

        await api.crud.create("SpEditRequest", {
          sampleProgramId: vm.formState.id,
          userId: vm.userId
        })

        vm.isRequestExist = true
      }
    },
  },
  async created() {
    const vm = this;
    let user = await api.userManager.myUser()
    let role = await api.userManager.myRole()
    vm.roleType = role.roleType
    vm.userId = user.id
    
    let myRequests = (await api.crud
      .gridData(
        "SpEditRequest", 
        {Items: [{field: "UserId", equal: vm.userId}, {field: "SampleProgramId", equal: vm.formState.id}]}
      ))
      .data;
    
    vm.isRequestExist = !!myRequests.length
    if(myRequests.some(function (item){return item.isApprove}))
      vm.message = "Запрос подтвержден"
    
    if(vm.roleType === 1){
      vm.filters = [{field: "SampleProgramId", equal: vm.formState.id}]
    }
  },
};
</script>