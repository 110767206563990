<template>
  <div>
    <a-form
      ref="form" 
      :model="state"
      :rules="validationRules"
    >
      <template v-for="(element, index) in itemsInner" :key="index">
        <LayoutItem 
          v-if="element.isLayout"
          :element="element"
        >
          <template #item="{ item }">
            <Item
              v-for="(el, i) in item.list"
              :key="i"
              :element="el"
              v-model:value="state[el.name]"
              :form-state="state"
              :represent="represent"
              @addChange="addChange(el)"
            />
          </template>
        </LayoutItem>

        <Item
          v-else-if="!element.hidden"
          :element="element"
          v-model:value="state[element.name]"
          :form-state="state"
          :represent="represent"
          @addChange="addChange(element)"
        />
      </template>
    </a-form>
  </div>
</template>

<script>
import Item from "@/components/form-generation/sp-form-generator-item.vue";
import LayoutItem from "@/components/form-generation/sp-form-generator-layout-item.vue";
import moment from "moment";
import { pick, cloneDeep } from "lodash";
import { itemTypes, getLayoutChildren } from "@/helpers/form-generator";

export default {
  name: "sp-form-generator",
  components: {
    Item,
    LayoutItem
  },
  props: {
    items: { type: Array, required: true },
    represent: { type: Object, required: false },
    readonly: { type: Boolean, default: false },
    objectData: { type: Object },
  },
  data() {
    return {
      state: {},
      validationRules: {},
      changedItems: [],
      itemsInner: []
    }
  },
  computed: {
    changedState() {
      const vm = this;
      
      let items = vm.itemsInner.filter(function(item){
        return item.tabs?.length
      }).flatMap(function(item){
        return item.tabs.flatMap(function(m){
          return m.list
        })
      })
      
      let referenceItems = vm.itemsInner.concat(items).filter(function(item){        
        return item.type?.includes(":") 
          && (item.type.split(":")[0] === itemTypes.reference || item.type.split(":")[0] === itemTypes.dependent)
          && !!item.type.split(":")[2]
      })
      
      vm.changedItems.forEach(function(item, i, arr){        
        let referenceItem = referenceItems.filter(function(f){
          return f.name === item
        })
        
        if(!referenceItem.length) return
        
        referenceItem = referenceItem[0]


        let prop = vm.state[item]
        if(prop){
          let id = vm.state[item].id

          item = referenceItem.type.split(":")[2]
          arr[i] = item
          vm.state[item] = id
        }
      })
      return pick(vm.state, vm.changedItems);
    },
  },
  methods: {
    async validate() {
      const vm = this;

      let errors = this.itemsInner
        .filter(function(item){ return item.required && !vm.state[item.name]})
            
      if(!errors.length)
        return {
          status: "success"
        }
      
      return{
        status: "failed",
        messages: errors.map(function (e){
          return e.label + " - обязательное поле"
        })
      }
    },
    clear() {
      this.clearChanges();
      for (const item of this.itemsInner)
        this.state[item.name] = null;
    },
    clearChanges() {
      this.changedItems = [];
    },
    addChange(item) {
      if (!this.changedItems.includes(item.name))
        this.changedItems.push(item.name);
    },
    initItem(item) {
      if (item.isLayout) {
        let children = getLayoutChildren(item);
        for (const child of children) 
          this.initItem(child);
        return;
      }

      if (!item.name)
        item.name = item.key;
      if (!item.name)
        return;

      if (item.value) {
        if (item.type === 'date' && item.value)
          this.state[item.name] = moment(item.value);
        else this.state[item.name] = item.value;
      } else {
        this.state[item.name] = undefined;
      }

      if (item.validationRules?.length > 0)
        this.validationRules[item.name] = item.validationRules;

      let readonly = this.readonly || (this.represent?.readOnlyFields?.includes(item.name) ?? false);
      if (!readonly && item.options?.editableStatuses?.length)
        readonly = !item.options.editableStatuses.some(id => id === this.objectData.programStatus?.id);

      if (readonly) {
        if (!item.options)
          item.options = {};
        // TODO: add real readonly mode
        item.options.disabled = true;
      }
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.state = {};
        this.validationRules = {};
        this.itemsInner = items.map(x => cloneDeep(x));
        for (const item of this.itemsInner) 
          this.initItem(item);
      }
    }
  },
  mounted() {
    this.$emit("initialized", this);
  }
};
</script>